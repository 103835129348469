import React from 'react';
import { Progress, Form } from 'antd';

import { getStrokeColor } from '../../../look';

import { withTeamsResiliencePulseCheck } from '../../containers/DashboardOperations';

const FormItem = Form.Item;

const TeamsResiliencePulseCheck = ({ loading, reportingTeamsResiliencePulseCheck }) => (
  <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label={`Reporting Team's Resilience Pulse Check`}>
    {console.log(reportingTeamsResiliencePulseCheck)}
    <Progress
      format={percent => percent / 10 + '%'}
      percent={
        (reportingTeamsResiliencePulseCheck && JSON.parse(reportingTeamsResiliencePulseCheck)?.overall?.toFixed(2) * 10) || 0
      }
      strokeColor={getStrokeColor(
        (reportingTeamsResiliencePulseCheck && JSON.parse(reportingTeamsResiliencePulseCheck)?.overall?.toFixed(2)) || 0
      )}
    />
  </FormItem>
);

export default withTeamsResiliencePulseCheck(TeamsResiliencePulseCheck);
