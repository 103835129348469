import React, { useState, useContext, useEffect } from 'react';
import '../css/ManagerAddCommitmentView.css'
import { Contextual } from '../../dashboard/components/CommitmentView';
import _ from 'lodash';
import { Checkbox, Input } from 'antd';
// import ReactMarkdown from '../ReactMarkdown';

import { Button, Modal, Row, Col, Form, Divider, Switch, CollapseProps, Collapse } from 'antd';
import { ArrowUpOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'
import { ArrowRightOutlined } from '@ant-design/icons';
import { CKEditorField, getIntFromString, SelectField, TextField } from '../../look';
import { ReactMarkdown } from '../../look/';
import '../css/ManagerAddCommitmentView.css';
import delete_btn from '../../assets/delete_icon.png'
// import ReactMarkdown from '../ReactMarkdown';


const { TextArea } = Input;

export const CKEditorFieldName = ['coachingCommitmentData', 'description'];

const ManagerAddCommitmentView = props => {
  const { getMyCustomizedCoachingCommitments, markCoachCommitment, onSubmit, modal3, modalTwoTrigger, setTitle, setModal3, setDescription, changeHandler, commitment, coachingComms, delPersonalComm, grabSeverities } = props;
  const { defaultCustomUserCommitments, callByUserId, coachCommByUser, dashboard, selectedMember, me } = useContext(Contextual)
  const [coachCommForIc, setCoachCommForIc] = useState()
  const [selectedComm, setSelectedComm] = useState()
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [memberName, setMemberName] = useState()


  // const [isNew, setIsNew] = useState(false);
  const [form] = Form.useForm();

  // const choices =
  //   getMyCustomizedCoachingCommitments &&
  //   getMyCustomizedCoachingCommitments.edges.length > 0 &&
  //   getMyCustomizedCoachingCommitments.edges.map(({ node }) => ({
  //     label: node.title || '',
  //     value: Number(getIntFromString(node.id))
  //   }));
  // console.log('defaultCustomUserCommitments ::', commitment?.user?.firstName)

  useEffect(()=>{
    if(selectedMember){
      let selectedMem = me?.employee?.teamMembersEmployee?.edges[0]?.node?.employees?.edges?.filter(emp => emp?.node?.user?.id === selectedMember?.userId)
      let selectedMemFirstName = selectedMem[0]?.node?.user?.firstName
      console.log('selectedMemNam98d9e', me, selectedMemFirstName)
      setMemberName(selectedMemFirstName)
    }
  },[selectedMember])

  useEffect(() => {
    let severitySort = coachingComms?.edges?.filter(comm => comm?.node?.coachingcommitment?.customusercommitment?.id === commitment?.id)
    console.log('severitySo87f7rt', severitySort)
    if (severitySort && severitySort.length) {
      let listOfSeverities = severitySort?.map(item => ({ dueDate: item?.node?.coachingcommitment?.customusercommitment?.dueDate, dateCreated: item?.node?.coachingcommitment?.dateCreated, id: item?.node?.coachingcommitment?.id, currentDate: new Date().toISOString(), completed: item?.node?.completed }))
      console.log('severitys3r', coachingComms, commitment, listOfSeverities)

      const endListOfSeverities = listOfSeverities?.map(x => {
        const currentDate = new Date().getTime();

        // Convert date strings to milliseconds
        const dateCreated = new Date(x.dateCreated).getTime();
        const dueDate = new Date(x.dueDate).getTime();

        // Calculate differences
        const diffToCreated = currentDate - dateCreated;
        const diffToDue = dueDate - currentDate;

        // Compare differences to determine the 'level'
        let level = '';
        if (diffToDue < 0) {
          // If diffToDue is negative
          level = 'red';
        } else if (diffToCreated < diffToDue) {
          // If currentDate is closer to dateCreated
          level = 'amber';
        } else {
          // If currentDate is closer to dueDate
          level = 'green';
        }
        let finalObj = { ...x, level, commitId: commitment.id }
        return finalObj
      })
      console.log('endLis565tOfS7jeverities', endListOfSeverities)
      grabSeverities(endListOfSeverities)
    } else {
      grabSeverities([{ level: 'gray', commitId: commitment.id }])

    }

  }, [coachingComms])

  useEffect(() => {
    console.log('commitment?.user?.id', modal3.visible, commitment?.user?.id, commitment)
    if (commitment?.user?.id) {
      callByUserId(getIntFromString(commitment?.user?.id))

    }
  }, [])
  console.log('coachComm344orIc', coachCommForIc, coachCommByUser)
  useEffect(() => {
    console.log('perfbon2dw', coachingComms?.edges?.map(item => item?.node?.coachingcommitment))

    let sortedData = coachingComms?.edges?.map(item => item?.node?.coachingcommitment)
    let sortedDataForStatus = coachingComms?.edges?.map(item => item?.node)
    let sortedCompStatus = sortedDataForStatus?.map(item => {
      return { completed: item?.completed, commitmentLinkId: item?.coachingcommitment?.id, coachCommId: item?.id, description: item?.description,dateCompleted: item?.dateCompleted }
    })
    let forThisCommitment = sortedData?.filter(item => item?.customusercommitment?.id === commitment?.id)
    console.log('rgrpinevv4', sortedCompStatus, forThisCommitment)

    setCoachCommForIc(forThisCommitment)
    setSelectedComm(sortedCompStatus)
  }, [coachCommByUser,coachingComms])
  console.log('coachCommF4vsaur4orIc', coachingComms)

  let onChange = () => {

  }
  console.log('comm due date printed here ::', commitment?.dueDate)
  return (
    <>
      <Modal
        footer={null}
        centered
        // className="ckeditor-parent_view"
        visible={modal3.visible}
        width={'50%'}
        destroyOnClose={true}
        onCancel={() => {
          setModal3({ visible: false, id: 0, default: false });
        }}
      >

        {commitment && <div style={{ display: `${typeof commitment === 'object' ? 'block' : 'none'}` }}>
          <h5 style={{ marginBottom: '1em', fontWeight: '600', fontSize: '1.1em' }}>Commitment Details</h5>

          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            // expandIcon={<ArrowUpOutlined />}
            expandIcon={({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />}
            defaultActiveKey={[]}
            style={{ backgroundColor: '#f5f5f5', width: '100%', borderRadius: '0.6em', marginBottom: '2em' }}

          >
            {commitment &&
              <Collapse.Panel
                className='collapse_main'
                header={
                  <>
                    <p style={{ fontWeight: '600', borderRadius: '0.6em' }}>{`${memberName}'s Commitment`}</p>
                    <Row style={{ display: 'flex', justifyContent: 'flex-start', gap: '0.5em', width: '100%', overflow: 'hidden' }}>
                      <p style={{ fontWeight: '600' }}>Title :</p>
                      <Col span={19}>
                        <p style={{ whiteSpace: 'initial', overflow: 'break-word', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>{commitment?.title ? commitment?.title : 'Title not provided'}</p>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Row style={{ marginTop: '-1em' }}>
                  <Col span={3}>
                    <p style={{ fontWeight: '600' }}>Due Date : </p>
                  </Col>
                  <Col span={21}>
                    <span style={{ fontWeight: '400' }}>{commitment?.dueDate ? new Date(commitment?.dueDate).toDateString() : 'Not Set'}</span>
                  </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'flex-start', gap: '0.5em', width: '100%', overflow: 'hidden' }}>
                  <p style={{ fontWeight: '600' }}>Description : </p>
                  <Col span={19}>
                    {/* <p style={{ whiteSpace: 'initial', overflow: 'break-word', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>{commitment?.description ? commitment?.description : 'Description not given'}</p> */}
                    <ReactMarkdown>{commitment?.description ? commitment?.description : 'Description not given'}</ReactMarkdown>
                  </Col>
                </Row>
              </Collapse.Panel>
            }
          </Collapse>

        </div>}
        {/* coaching commitments given  */}
        <Row style={{height:`${!coachCommForIc?.length ? 'unset' : '24em'}`,overflowY:'scroll'}}>
        {coachCommForIc && coachCommForIc?.map(item => {
          let selectedCommMarkId = selectedComm?.filter(commItem => commItem?.commitmentLinkId === item?.id)
          
          let selectedNode = coachingComms?.edges?.filter(x => x?.node?.coachingcommitment?.id === item?.id)
          console.log('agayacommitmentrid6r6', item,coachCommForIc)
          return (
            <div style={{ borderRadius: '0.6em', backgroundColor: '#f7f7f7', marginBottom: '1em',width:'100%',padding:'1em 0em' }}>
              <Row >
                <div style={{ width: '100%' }}>
                  <p style={{ fontWeight: '600', marginBottom: '0.6em', margin: '0 0.5em 0.6em 0em',paddingLeft:'0.8em' }}>{`${item?.title}`}</p>
                  <div style={{ width: '100%', height: '1.2px', backgroundColor: '#e0e0e0' }}></div>
                  <p style={{ margin: '1.2em 0em', paddingLeft:'0.8em', width:'100%',maxHeight:'4em', overflow:'scroll', paddingRight:'0.8em' }}><ReactMarkdown>{item?.description}</ReactMarkdown></p>
                  <Row style={{  alignItems: 'center',justifyContent:'flex-start',gap:'0.8em', padding: '0em 0.5em 0em 0.8em' }}>
                    <Col  span={15} style={{display:'flex',flexDirection:'row',border:'1px solid rgba(0, 0, 0, 0.1)',borderRadius:'1em',height:'5.65em'}}>
                      <Row >
                        <Col style={{display:'flex',flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start',padding:'0 1em'}}>
                          <p style={{fontWeight:'700',marginBottom:'0',marginTop:'0.7em'}}>Committed On</p>
                          <p style={{marginTop:'0.7em',fontSize:'0.8em'}}>{commitment?.dateCreated && new Date(commitment?.dateCreated).toDateString()}</p>
                        </Col>
                        <Col style={{display:'flex',flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start',padding:'0 1em'}}>
                        <p style={{fontWeight:'700',marginBottom:'0',marginTop:'0.7em'}}>Completed On</p>
                          <p style={{marginTop:'0.7em',fontSize:'0.8em'}}>{selectedCommMarkId[0].dateCompleted ? new Date(selectedCommMarkId[0].dateCompleted).toDateString() : 'Not Completed'}</p>
                        </Col>
                        <Col style={{display:'flex',flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start',padding:'0 1em'}}>
                        <p style={{fontWeight:'700',marginBottom:'0',marginTop:'0.7em'}}>Due Date</p>
                          <p style={{marginTop:'0.7em',fontSize:'0.8em'}}>{item?.dueDate ? new Date(item?.dueDate).toDateString() : 'Not Set'}</p>
                        </Col>
                      </Row>

                    </Col>
                    <Col style={{display:'flex',justifyContent:'center',alignItems:'center',height:'5.65em',border:'1px solid rgba(0, 0, 0, 0.1)',borderRadius:'1em',gap:'0.4em'}} span={6}>
                      <Checkbox className='checkbox_cont' checked={selectedNode[0]?.node?.completed} onClick={(e) => markCoachCommitment({
                        completed: e?.target?.checked,
                        id: getIntFromString(selectedCommMarkId[0].coachCommId)
                      })} />
                      <p style={{ marginBottom: '0em', fontWeight:'2em',fontSize:'0.8em' }}>Mark As Completed</p>
                    </Col>
                    <Col span={2} style={{border:'1px solid rgba(0, 0, 0, 0.1)',borderRadius:'1em',height:'5.65em'}}>
                      <Row style={{ justifyContent: 'center', alignItems: 'center', height: '100%', padding: '0 0.8em' }}>
                        <img style={{ width: '1.7em', height: '1.7em', cursor: 'pointer' }} src={delete_btn} onClick={() => setIsDeleteModal(true)} />
                        {
                          <Modal
                            footer={null}
                            centered
                            visible={/* true || */ isDeleteModal}
                            destroyOnClose={true}
                            onCancel={() => setIsDeleteModal(false)}
                          >
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1em 2em' }}>
                              <h2 style={{ fontSize: 'larger', fontFamily: 'poppins, sans-serif', fontWeight: '500', textAlign: 'center', width: 'max-content', marginTop: '0.6em' }}>Are you sure you want to delete this Commitment?</h2>
                              <p style={{ fontSize: 'small', fontFamily: 'poppins, sans-serif', textAlign: 'center', width: 'max-content' }}>Deleting the Commitment will permanently remove it from the records!</p>
                              <Button style={{ boxShadow: '0px 4px 11.800000190734863px 0px rgba(165, 196, 43, 0.2)', border: '1px solid #B0CB1F', borderRadius: '0.4em', marginTop: '1em' }} type="link" onClick={() => { delPersonalComm(getIntFromString(dashboard === 'manager-personal' ? selectedNode[0]?.node?.id : selectedNode[0]?.node?.id)); setIsDeleteModal(false) }}>Delete Commitment</Button>
                            </div>
                          </Modal>
                        }
                      </Row>
                    </Col>

                  </Row>
                </div>
              </Row>

            </div>
          )
        })
        }
        </Row>


        <Form form={form} layout="vertical" className="ckeditor-parent" onFinish={values => onSubmit(values)} required={false} scrollToFirstError={true}>

          {/* <Row gutter={24} justify="space-between">
            <Col span={16}>
              <SelectField
                choices={choices}
                name={['coachingCommitmentData', 'customusercommitmentId']}
                label="Select from your custom coaching commitments"
                disabled={isNew}
                onChangeSelect={id => {
                  const coachingCommitment =
                    getMyCustomizedCoachingCommitments.edges.length > 0 &&
                    getMyCustomizedCoachingCommitments.edges.filter(({ node }) => getIntFromString(node.id) === id)[0];
                  console.log(coachingCommitment);
                  form.setFieldsValue(
                    _.set(
                      form.getFieldsValue(true),
                      ['coachingCommitmentData', 'title'],
                      coachingCommitment.node.title || ''
                    )
                  );
                  form.setFieldsValue(
                    _.set(
                      form.getFieldsValue(true),
                      ['coachingCommitmentData', 'commitmentId'],
                      getIntFromString(coachingCommitment.node.commitment.id) || ''
                    )
                  );
                }}
              />
              <Col span={0}>
                <TextField
                  name={['coachingCommitmentData', 'commitmentId']}
                  label="Title for your commitment"
                  disabled={!isNew}
                />
              </Col>
            </Col>
            <Col span={4} align="right" style={{ alignItems: 'center' }}>
              {'Add New '}
              <Switch
                checked={isNew}
                onChange={v => {
                  setIsNew(v);
                  form.resetFields();
                }}
              />70%
            </Col>
          </Row>
          <Row>
            <Divider style={{ width: '100%', alignItems: 'flex-start' }}>OR</Divider>
          </Row> */}

        </Form>
        {!coachCommForIc?.length && <Row style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(217, 217, 217, 0.1)', padding: '5em 1em', borderRadius: '0.7em' }}>
          <h5 style={{ fontSize: '1.7em', fontWeight: '400', fontFamily: 'poppins, sans-serif', marginBottom: '1em' }}>Take the next steps in your coaching journey by solidifying your commitment now!</h5>
          {/* <p>Please go ahead and give a Coaching Commitment</p> */}
          {/* <button onClick={() => { setModal3({ visible: false, id: 0, default: false }); modalTwoTrigger() }}>Add a Coaching Commitment</button> */}
          <Button onClick={() => { setModal3({ visible: false, id: 0, default: false }); modalTwoTrigger() }} style={{ boxShadow: '0px 4px 11.800000190734863px 0px rgba(165, 196, 43, 0.2)', border: '1px solid rgba(165, 196, 43, 1)', borderRadius: '0.5em', fontSize: '1.4em', fontWeight: '500', height: '2.2em', width: '15em' }} type="link" >
            Add Coaching Commitment
          </Button>
        </Row>}
      </Modal>
    </>
  );
};

export default ManagerAddCommitmentView;
