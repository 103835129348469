import React, { useState, useEffect, useContext } from 'react';
import { Contextual } from './CommitmentView';
import styled from 'styled-components';
import { Modal, Checkbox, Button, Space, Row, Col, Card, Divider, Tooltip } from 'antd';
import { ReactMarkdown, getIntFromString, DeleteIcon } from '../../look/';
import ManagerAddCommitment from '../../manager/containers/ManagerAddCommitment';
import '../css/CommitmentComponent.css';
import GroupImg from '../../assets/Group_395.svg';
import giveCoachBtn from '../../assets/Group_812.svg'
import ManagerViewCommitments from '../../manager/containers/ManagerViewCommitments';
import { findAllByDisplayValue } from '@testing-library/react';
import delete_btn from '../../assets/delete_icon.png'

// import axios from 'axios';


const CommitmentComponent = props => {
  const {
    commitment,
    completed,
    onCustomUserCommitment,
    onDefaultCommitmentProgress,
    dateCreated,
    dateCompleted,
    dashboard,
    setUpdated,
    defaultCustomUserCommitment
  } = props;

  const [localCompleted, setLocalCompleted] = useState();
  const [visible, setVisible] = useState(false);
  const [suggestion, setSuggestion] = useState({ title: '', snippet: '', link: '' })
  const [modal2, setModal2] = useState({ visible: false, id: 0, default: false });
  const [modal3, setModal3] = useState({ visible: false, id: 0, default: false });
  const [selectedComm, setSelectedComm] = useState()
  const [coachCommForIc, setCoachCommForIc] = useState()
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [severityLvl, setSeverityLvl] = useState()
  const [recoIdToDelete, setRecoIdToDelete] = useState()
  const [userCommForId, setUserCommForId] = useState()

  // Use useEffect to update localCompleted when completed changes

  const { defaultCustomUserCommitments, delPersonalComm, coachCommByUser, severity, personalCommitmentList } = useContext(Contextual)


  console.log('checked54value ::', commitment, personalCommitmentList)
  useEffect(() => {
    if(personalCommitmentList){
      let selectedCommitment = personalCommitmentList?.edges?.filter(item => item?.node?.id === commitment?.id)
      console.log('seleu99dctedCommitment',selectedCommitment)
      setUserCommForId(selectedCommitment[0])
    }
  }, [personalCommitmentList])
  function handleChange(e) {
    // console.log('checked54value ::', e.target.checked, defaultCustomUserCommitments)
    onCustomUserCommitment &&
      onCustomUserCommitment({
        id: getIntFromString(commitment?.id),
        title: commitment?.title,
        toolId: getIntFromString(commitment?.tool?.id),
        completed: e?.target?.checked
      }) &&
      setUpdated();
    onDefaultCommitmentProgress &&
      onDefaultCommitmentProgress({
        completed: e?.target?.checked,
        notes: commitment?.notes
      }) &&
      setUpdated();
  }

  useEffect(() => {
    // console.log('perfbon2dw',coachCommByUser, coachCommByUser?.data?.getMyCoachingCommitmentsProgress?.edges?.map(item => item?.node?.coachingcommitment))
    let sortedData = coachCommByUser?.data?.getMyCoachingCommitmentsProgress?.edges?.map(item => item?.node?.coachingcommitment)
    let forThisCommitment = sortedData?.filter(item => item?.customusercommitment?.id === commitment?.id)
    console.log('perfbon2dw', forThisCommitment, coachCommByUser, coachCommByUser?.data?.getMyCoachingCommitmentsProgress?.edges?.map(item => item?.node?.coachingcommitment))

    setCoachCommForIc(forThisCommitment)
  }, [coachCommByUser])

  useState(() => {
    if (defaultCustomUserCommitments?.edges) {
      let commToDelete = defaultCustomUserCommitments?.edges?.filter(commit => commit?.node?.commitment?.id === commitment?.id)
      console.log('hdeyggdye7t7ed', commToDelete, commitment?.id, defaultCustomUserCommitments)
      let idToDelete = commToDelete[0]?.node?.id
      setRecoIdToDelete(idToDelete)
    }
  }, [defaultCustomUserCommitments])

  useEffect(() => {
    if (defaultCustomUserCommitments) {
      let selectedCommitment = defaultCustomUserCommitments?.edges?.filter(item => item?.node?.coachingcommitment?.title === commitment?.title)
      console.log('selectedC756k0:', selectedCommitment)
      setSelectedComm(selectedCommitment[0])
    }
  }, [defaultCustomUserCommitments])

  function handleSet(e, changeId, changeTitle) {
    const selectedCommState = defaultCustomUserCommitments?.edges?.filter(item => item?.node?.coachingcommitment?.id === changeId && item?.node?.coachingcommitment?.title === changeTitle)

    setLocalCompleted(!selectedCommState[0]?.node?.completed)
  }



  // function to compare dates
  function compareDates(dateStr1, dateStr2) {
    // Convert the date strings to JavaScript Date objects
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    // Compare the Date objects
    if (date1.getTime() <= date2.getTime()) {
      // Date1 matches or is smaller (before) Date2
      return true;
    } else {
      // Date1 is larger (after) Date2
      return false;
    }
  }

  let severityIcons = {
    red: 'https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1705391077/WorkplaceResilienceProduction/Tools/Images/high_priority_1_n1bzdv.gif',
    green: 'https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1704452776/WorkplaceResilienceProduction/Tools/Images/severity_green_twnsfv.svg',
    amber: 'https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1704452776/WorkplaceResilienceProduction/Tools/Images/severity_amber_meklan.svg',
    gray: 'https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1704452776/WorkplaceResilienceProduction/Tools/Images/severity_gray_gx3bth.svg'
  }

  const modalTwoTrigger = () => {
    setModal2({ visible: true, id: getIntFromString(commitment.id), default: defaultCustomUserCommitment })
  }

  let grabSeverities = (valArray) => {
    console.log('valuesdhere3', valArray)

    if (valArray[0].level !== 'gray') {

      let redCount = 0;
      let greenCount = 0;
      let amberCount = 0;
      let commitId;
      for (let i = 0; i < valArray.length; i++) {
        if (valArray[i].level === 'red' && !valArray[i].completed) {
          redCount++;
          commitId = valArray[i].commitId
        } else if (valArray[i].level === 'green' && !valArray[i].completed) {
          greenCount++;
          commitId = valArray[i].commitId

        } else if (valArray[i].completed){
          greenCount++;
          commitId = valArray[i].commitId

        } else if (valArray[i].level === 'amber' && !valArray[i].completed) {
          amberCount++;
          commitId = valArray[i].commitId
        }
      }

      let cumulativeLevel = '';
      if (amberCount > redCount && amberCount > greenCount) {
        cumulativeLevel = 'amber';
      } else if (greenCount > amberCount && greenCount > redCount) {
        cumulativeLevel = 'green';
      } else if (redCount > amberCount && redCount > greenCount) {
        cumulativeLevel = 'red';
      } else {
        cumulativeLevel = 'amber';

      }
      console.log('red count green count for ',redCount, greenCount,amberCount, commitment?.title, cumulativeLevel )

      const resultArray = [{ cumulativeLevel, commitId }];
      // setSeverity(resultArray)
      setSeverityLvl(resultArray)
      console.log('valArray8rh84', resultArray, redCount / greenCount);

    } else {
      // const resultArray = [{ cumulativeLevel: 'gray' }];
      // setSeverity(resultArray)
      setSeverityLvl([{ ...valArray[0], cumulativeLevel: 'gray' }])
    }
  }
  console.log('modals230', severityLvl, severityIcons)

  return (
    <Card bordered={false}
      style={{
        display: 'grid',
        width: '100%',
        borderRadius: '0.3em',
        padding: '0.2em 0',
        boxShadow: '0px 4px 11.800000190734863px 0px rgba(0, 0, 0, 0.1)'
      }}
      bodyStyle={{ padding: "10px" }}>
      <Hoverable>
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Row style={{ width: '100%', cursor:'default' }}>
              {dashboard === 'manager' && <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} span={dashboard === 'manager' ? 2 : 0}>
                <img style={{ width: '47.16px', height: '47.16px' }} src={`${severityLvl && severityLvl[0]?.commitId === commitment?.id && severityIcons[severityLvl[0]?.cumulativeLevel]}`} alt="" />
                {/* <img style={{width:'86%', height:'86%'}} src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1704452776/WorkplaceResilienceProduction/Tools/Images/severity_red_vykxtj.svg" alt="" /> */}
              </Col>}
              <Col span={dashboard === 'manager' ? 17 : 19} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: `${compareDates(new Date(commitment.dueDate).toDateString(), new Date().toDateString()) && commitment.dueDate ? `${completed ? 'black' : 'red'}` : 'black'}`, textWrap: 'balance', fontFamily: 'poppins, sans-serif' }}>{commitment?.title}</div>
              </Col>

              <Col span={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: 'max-content', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '2em', marginLeft: 'auto', marginRight: '0.5em' }}>
                  <Tooltip style={{ pointerEvents: `${dashboard === 'manager' ? 'auto' : 'none'}`, visibility: `${dashboard === 'manager' ? 'hidden' : 'visible'}` }} onClick={() => setModal2({ visible: true, id: getIntFromString(commitment.id), default: defaultCustomUserCommitment })} title="Add Coaching Commitment">
                    <img src={giveCoachBtn} style={{ height: '20%', width: '20%', pointerEvents: `${dashboard === 'manager' ? 'auto' : 'none'}`, visibility: `${dashboard === 'manager' ? 'visible' : 'hidden'}`, cursor: 'pointer' }} alt="give_coach_comm" />
                  </Tooltip>
                  <Tooltip onClick={() => {setModal3({ visible: true, id: getIntFromString(commitment.id), default: defaultCustomUserCommitment });dashboard !== 'manager' && setVisible(true)}} title="Commitment Details">
                    <img src={GroupImg} style={{ height: '20%', width: '20%', cursor: 'pointer' }} alt="coach_commitments" />
                  </Tooltip>
                </div>
              </Col>

            </Row>
          </Col>

        </Row>
      </Hoverable>
      {dashboard === 'manager' && <ManagerAddCommitment commitment={commitment} modal2={modal2} setModal2={setModal2} />}
      {dashboard === 'manager' && <ManagerViewCommitments grabSeverities={grabSeverities} commitment={commitment} modal3={modal3} setModal3={setModal3} modalTwoTrigger={modalTwoTrigger} />}
      <Modal
        footer={null}
        centered
        visible={/* getIntFromString(commitment.id) === 1 || */ visible}
        width={'unset'}
        className='comm_detail_modal'
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        style={{ padding: '2em' }}
      >
        <h3 style={{ marginTop: 'auto', textOverflow: 'ellipsis',marginRight:'1em', marginBottom:'1em', marginLeft:'1em' }}>{commitment?.title}</h3>
        <div
          style={{
            border: '1px solid rgba(202, 202, 202, 0.25)',
            margin: '7px',
            padding: '15px',
            borderRadius: '2px',
            minHeight: '180px',
            overflow: 'scroll'
          }}
        >
          <p style={{ width: 'inherit' }}>{commitment?.description}</p>
        </div>
        <br />

        <Row gutter={[24, 24]} wrap={false} justify="space-between" style={{ width: 'max-content', padding: '0 7px' }} align="middle">
          <Col span={14}>
            <Row wrap={false} style={{ width: '100%', backgroundColor: 'rgba(217, 217, 217, 0.1)', padding: '1em', borderTop: '1px solid rgba(0, 0, 0, 0.1)', borderBottomLeftRadius: '0.7em', borderBottomRightRadius: '0.7em' }}>
              <Col span={8}>
                <Row style={{ flexDirectxion: 'column' }}>
                  <p style={{ fontWeight: '600', fontSize: '1.1em' }}>Committed on</p>
                  <p style={{ fontSize: '12px' }}>{new Date(dateCreated).toDateString()}</p>
                </Row>
              </Col>
              <Col span={8}>
                <p style={{ fontWeight: '600', fontSize: '1.1em' }}>Completed on</p>
                <p style={{ fontSize: '12px' }}>{dateCompleted ? new Date(dateCompleted).toDateString() : 'Not-completed'}</p>
              </Col>
              <Col span={8} style={{ color: `${compareDates(new Date(commitment.dueDate).toDateString(), new Date().toDateString()) && commitment.dueDate ? `${completed ? 'black' : 'red'}` : 'black'}` }}>
                <p style={{ fontWeight: '600', fontSize: '1.1em' }}>Due Date</p>
                <p style={{ fontSize: '12px' }}>{commitment?.dueDate ? new Date(commitment?.dueDate).toDateString() : 'Not Set'}</p>
              </Col>
            </Row>
          </Col>
          <Col span={7} style={{ height: '7em' }}>
            {dashboard !== 'manager' && (
              <>
                <Row style={{ width: 'fit-content', height: '100%', backgroundColor: 'rgba(217, 217, 217, 0.1)', borderTop: '1px solid rgba(0, 0, 0, 0.1)', borderBottomLeftRadius: '0.7em', borderBottomRightRadius: '0.7em', padding: '0 1.4em' }} justify='center' align='middle'>
                  <Col style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} span={24}>
                    <Checkbox className='checkbox_cont' title={false} checked={completed} onClick={(e) => handleSet(e, commitment.id, commitment.title)} onChange={handleChange}>
                    </Checkbox>
                    <p style={{ marginBottom: 'unset', whiteSpace: 'nowrap', marginLeft: '1em', fontWeight: '400' }}>Mark As Completed</p>
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col span={3} style={{ height: '7em' }}>
            <Row style={{ justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: 'rgba(217, 217, 217, 0.1)', borderTop: '1px solid rgba(0, 0, 0, 0.1)', borderBottomLeftRadius: '0.7em', borderBottomRightRadius: '0.7em', padding: '0 1.4em' }}>
              <img style={{ width: '70%', height: 'auto', cursor: 'pointer' }} src={delete_btn} onClick={() => setIsDeleteModal(true)} />
              {
                <Modal
                  footer={null}
                  centered
                  visible={/* true || */ isDeleteModal}
                  destroyOnClose={true}
                  onCancel={() => setIsDeleteModal(false)}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1em 2em' }}>
                    <h2 style={{ fontSize: 'larger', fontFamily: 'poppins, sans-serif', fontWeight: '500', textAlign: 'center', width: 'max-content', marginTop: '0.6em' }}>Are you sure you want to delete this Commitment?</h2>
                    <p style={{ fontSize: 'small', fontFamily: 'poppins, sans-serif', textAlign: 'center', width: 'max-content' }}>Deleting the Commitment will permanently remove it from the records!</p>
                    <Button style={{ boxShadow: '0px 4px 11.800000190734863px 0px rgba(165, 196, 43, 0.2)', border: '1px solid #B0CB1F', borderRadius: '0.4em', marginTop: '1em' }} type="link" onClick={() => { delPersonalComm(getIntFromString(dashboard === 'manager-personal' ? selectedComm?.node?.id : getIntFromString(userCommForId?.node?.id))); setIsDeleteModal(false) }}>Delete Commitment</Button>
                  </div>
                </Modal>
              }
            </Row>
          </Col>
        </Row>
      </Modal>
    </Card>
  );
};

export default CommitmentComponent;

const Hoverable = styled.div`
  white-space: wrap;
  overflow: ellipsis;
  // :hover {
  //   cursor: pointer;
  // }
`;
