import React, { useEffect, useState, createContext, useContext } from "react";
import { Col, Card, Row, Spin, Empty } from "antd";
import { DoubleRightOutlined, CloseOutlined } from "@ant-design/icons";
import "../css/CommitmentView.css";
import { getIntFromString } from "../../look";
import { USER_IMG } from '../../../config';

import ManagerCommitmentProgressBar from "../../manager/containers/ManagerCommitmentProgressBar";
import CoachingCommitmentProgressBar from "../../manager/containers/CoachingCommitmentProgressBar";
import PersonalCommitmentProgressBar from "../containers/PersonalCommitmentProgressBar";

import PersonalEditCommitmentView from "./PersonalEditCommitmentView";
import CommitmentComponent from "./CommitmentComponent";
import { Tipcontext } from "./PersonalDashboardView";

export const Contextual = createContext();

const CommitmentView = (props) => {
  const {
    loading,
    coachCommByUser,
    setManCoComm,
    setPerCommOpen,
    callByUserId,
    customUserCommitments,
    defaultCustomUserCommitments,
    allDefaultCommitmentsProgress,
    onCustomUserCommitment,
    onDefaultCommitmentProgress,
    dashboard,
    employeeId,
    selectedMember,
    personalCommitmentList,
    setMemberName,
    memberName,
    delPersonalComm,
    setActiveDashboard,
    me,
    title = "Commitments Completion Status",
    forUser,
    commitmentKey = "commitment",
    getMyCustomizedCoachingCommitments,
  } = props;
  const [visible, setVisible] = useState(false);
  const [updated, setUpdated] = useState(0);
  const [isManageComms, setIsManageComms] = useState();
  const [selectedEmp, setSelectedEmp] = useState();
  const [severity, setSeverity] = useState()
  const scrollToComm = React.useRef(() => {});

  console.log("cust33omUserComm7t7itments new ::", selectedEmp);
  let selectedEmployeeFiltered =
    me?.employee?.teamManagerEmployee?.edges[0].node?.employees?.edges?.filter(
      (item) => item.node.id === selectedMember?.employeeId
    );

  let value = useContext(Tipcontext)
  useEffect(() => {
    if (value?.tipPosted) {
      // setVisible(prev => !prev)
      console.log('prevnext')
    }
  }, [value?.tipPosted])

  useEffect(() => {
    if (selectedMember) {
      let selectedEmployeeName =
        me?.employee?.teamManagerEmployee?.edges[0].node?.employees?.edges
          ?.map((item) => {
            if (
              item.node.id === selectedMember?.employeeId &&
              item.node.user.id === selectedMember?.userId
            ) {
              return item.node.user.username;
            } else {
              return null;
            }
          })
          .filter((item) => item !== null);

      if (selectedEmployeeName !== undefined) {
        setMemberName(selectedEmployeeName);
        setSelectedEmp(selectedEmployeeName);
      }
    }
  }, [selectedMember]);

  useState(() => {
    if (setActiveDashboard) {
      setActiveDashboard(dashboard)
    }
  }, [dashboard === 'manager-personal'])

  // console.log('selected member name ::::', defaultCustomUserCommitments)
  console.log(
    "allDefaultCommitmentsProgress ::",
    defaultCustomUserCommitments,
    dashboard
  );


  // let grabSeverities = (valArray) => {
  //   if(valArray.length){
  //     console.log('valuesdhere3',valArray)
  //     // const valArray = [
  //     //   { dueDate: '2023-12-15T10:59:54.395000+00:00', dateCreated: '2024-01-04T10:06:49.599839+00:00', id: 'Q29hY2hpbmdDb21taXRtZW50VHlwZTo0NjU=', level: 'red' },
  //     //   { dueDate: '2023-12-15T10:59:54.395000+00:00', dateCreated: '2024-01-04T10:06:49.599839+00:00', id: 'Q29hY2hpbmdDb21taXRtZW50VHlwZTo0NjU=', level: 'green' },
  //     //   { dueDate: '2023-12-15T10:59:54.395000+00:00', dateCreated: '2024-01-04T10:06:49.599839+00:00', id: 'Q29hY2hpbmdDb21taXRtZW50VHlwZTo0NjU=', level: 'green' },
  //     //   { dueDate: '2023-12-15T10:59:54.395000+00:00', dateCreated: '2024-01-04T10:06:49.599839+00:00', id: 'Q29hY2hpbmdDb21taXRtZW50VHlwZTo0NjU=', level: 'red' },
  //     //   { dueDate: '2023-12-15T10:59:54.395000+00:00', dateCreated: '2024-01-04T10:06:49.599839+00:00', id: 'Q29hY2hpbmdDb21taXRtZW50VHlwZTo0NjU=', level: 'red' }
  //     //   // Add more objects as needed
  //     // ];

  //     let redCount = 0;
  //     let greenCount = 0;
  //     let commitId;
  //     for (let i = 0; i < valArray.length; i++) {
  //       if (valArray[i].level === 'red') {
  //         redCount++;
  //         commitId= valArray[i].commitId
  //       } else if (valArray[i].level === 'green') {
  //         greenCount++;
  //       }
  //     }

  //     let cumulativeLevel = '';

  //     if (redCount === 0) {
  //       cumulativeLevel = 'green';
  //     } else if (greenCount === 0) {
  //       cumulativeLevel = 'red';
  //     } else if (redCount/greenCount > 0.5) {
  //       cumulativeLevel = 'amber';
  //     } 

  //     const resultArray = [{ cumulativeLevel, commitId }];
  //     setSeverity(resultArray)
  //     console.log('valArray8rh84',resultArray, redCount/greenCount);

  //   } else {
  //     const resultArray = [{ cumulativeLevel: 'gray' }];
  //     setSeverity(resultArray)
  //   }
  // }
  console.log('bbubuf88034', visible)

  useEffect(()=>{
    if(visible && dashboard === 'personal'){
      scrollToElement()
      // triggerAnimation()
    }
  },[visible])

  const scrollToElement = () => {
    if (scrollToComm.current) {
      scrollToComm.current.scrollIntoView({
        behavior: 'smooth', // Optional: Adds smooth scrolling behavior
        block: 'start', // Optional: Scrolls to the top of the element
      });
    }
  };

  return (
    <Contextual.Provider value={{ defaultCustomUserCommitments, memberName, callByUserId, coachCommByUser, delPersonalComm, dashboard, severity, selectedMember, me,personalCommitmentList }}>
      <>
        <Col
          className={`transition-col ${visible ? "visible" : "hidden"}`}
          span={visible ? 24 : 8}
        >
          <Card
            style={{ display: 'flex', flexDirection: 'column', height: "100%" }}
            bodyStyle={{
              boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
              borderRadius: "2%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: '100%',
              padding: '20px',
              cursor:`${visible? 'default' : "pointer"}`
            }}
            onClick={()=> !visible && setVisible(true)}
            bordered={false}
          >

            <div
              style={{
                width: "100%",
                height: 'min-content',
                display: `${visible ? "flex" : "none"}`,
                justifyContent: "space-between",
                marginBottom: "17px",
              }}
            >
              {visible && (
                <>
                  {!loading &&
                    <Col>
                      {selectedEmp ? (
                        <div style={{ display: 'flex', gap: '1em', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '0.4em' }}>
                          <img
                            alt=""
                            src={me?.profile?.profileImage || USER_IMG}
                            style={{ width: '5.1em', height: '5.1em', borderRadius: '50%' }}
                          />
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.4em' }}>
                            <h2 style={{ fontWeight: "600", marginBottom: '0em', fontSize: '1.8em', fontFamily: 'poppins, sans-serif' }}>
                              {dashboard === "manager"
                                ? `${selectedEmp[0]
                                  .slice(0, 1)
                                  .toUpperCase()}${selectedEmp[0]
                                    .slice(1)
                                    .toLowerCase()}'s commitments`
                                : "Commitments"}
                            </h2>
                            <p style={{ fontWeight: '500', marginBottom: '0em', fontFamily: 'poppins, sans-serif' }}>{`Choose a commitment to kickstart a coaching journey for ${selectedEmp[0]
                                  .slice(0, 1)
                                  .toUpperCase()}${selectedEmp[0]
                                    .slice(1)
                                    .toLowerCase()}!`}</p>
                          </div>
                        </div>


                      ) : (
                        <h2 style={{ fontWeight: "normal" }}>
                          {dashboard === "manager-personal"
                            ? `My Coaching Commitments for ${memberName &&
                            memberName?.map(
                              (a) =>
                                a.charAt(0).toUpperCase() + a.substr(1)
                            )
                            }`
                            : "My Commitments"}
                        </h2>
                      )}
                    </Col>
                  }

                  <CloseOutlined
                    onClick={() => {setVisible(false);dashboard === 'personal' && setPerCommOpen(false);dashboard === 'manager' && setManCoComm(false)}}
                    style={{ fontSize: "20px", alignSelf: "right" }}
                    
                  />
                </>


              )}
            </div>
            <Col
              style={{
                display: `${visible ? "none" : "block"}`,
                position: "absolute",
                top: "25px",
                right: "27px",
                // zIndex: "1000",
              }}
              span={2}
              align="right"
            >
              {!visible && (
                // <Tooltip title="This feature is comming soon!">
                <DoubleRightOutlined
                  onClick={() => {setVisible(true);dashboard === 'personal' && setPerCommOpen(true);dashboard === 'manager' && setManCoComm(true)}}
                  style={{
                    fontSize: "20px",
                    transform: "rotateZ(90deg)",
                  }}
                />
                // </Tooltip>
              )}
            </Col>
            <Row style={{ height: '60vh' }} /* gutter={24} */ align="middle">
              <Col
                span={visible ? 7 : 24}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: `${visible ? '0px 4px 11.800000190734863px 0px rgba(0, 0, 0, 0.1)' : ''}`,
                  height: '100%',
                  borderRadius: '0.8em'
                }}
              >
                <div align="center" className="progress_bars" style={{ width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: '1em',height:'100%' }}>
                  <Row
                    align="middle"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: '2em',
                      marginBottom: '3em'
                    }}
                  >
                    <Col span={24} style={{ padding: '0 1em' }}>
                      <h2 style={{ fontWeight: `${visible ? '600' : '400'}`, margin: '-0.5em 0', fontSize: `${visible ? '1em' : '1.5em'}` }}>
                        {selectedEmp ? `
                        ${selectedEmp[0]
                          .slice(0, 1)
                          .toUpperCase()}${selectedEmp[0]
                            .slice(1)
                            .toLowerCase()}'s 
                        ${title} ` : `${title} `}
                        {/* <br /> */}
                        {dashboard !== "manager" && "Progress Bar"}
                      </h2>
                    </Col>
                  </Row>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', margin: `${dashboard === 'manager' ? '0 2em' : 'none'}`, marginTop: '-2em' }}>
                    {dashboard === "manager" && visible && (
                      <CoachingCommitmentProgressBar
                        style={{ borderBottom: '1px solid gray' }}
                        visible={visible}
                        defaultCustomUserCommitments={
                          defaultCustomUserCommitments
                        }
                        updated={updated}
                      />
                    )}

                    {dashboard === "manager" && visible && (
                      <ManagerCommitmentProgressBar
                        style={{ borderBottom: '1px solid gray' }}
                        visible={visible}
                        customUserCommitments={customUserCommitments}
                        employeeId={employeeId}
                        updated={updated}
                      />
                    )}
                  </div>

                  {dashboard === "manager" && visible && <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0 2.08em', gap: '0.8em', marginTop: '-0.8em' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <div style={{ width: '15px', height: '15px', backgroundColor: 'rgba(165, 196, 43, 1)', marginRight: '1em' }}></div>
                      <p style={{ fontSize: '0.8em', whiteSpace: 'nowrap', marginBottom: '0em', fontWeight: '500' }}>1. Coaching Commitment</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <div style={{ width: '15px', height: '15px', backgroundColor: 'rgba(196, 151, 43, 1)', marginRight: '1em' }}></div>
                      <p style={{ fontSize: '0.8em', whiteSpace: 'nowrap', marginBottom: '0em', fontWeight: '500' }}>2. Employee Commitment</p>
                    </div>
                  </div>}

                  {dashboard === "personal" && (
                    <PersonalCommitmentProgressBar
                      visible={visible}
                      setIsManageComms={setIsManageComms}
                      personalCommitmentList={personalCommitmentList}
                      updated={updated}
                    />
                  )}
                  {
                    dashboard === 'personal' && !visible && <div style={{width:'100%',height:'3em'}}></div>
                  }
                  {dashboard !== "manager" && visible && <Row style={{ justifyContent: 'center', marginTop: '3em' }}>
                    <div style={{ padding: '0.6em 0.8em', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.5em', border: '1px solid rgba(165, 196, 43, 1)' }}>
                      <p ref={scrollToComm} style={{ marginBottom: '0em', color: 'rgba(165, 196, 43, 1)', cursor: 'pointer' }} onClick={() => setIsManageComms(true)}>Add Commitment</p>
                    </div>
                  </Row>}
                </div>

                <Row
                  align="middle"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    pointerEvents: 'none',
                    visibility: 'hidden'
                  }}
                >
                  <Col span={21}>
                    <h5 style={{ fontWeight: "normal" }}>
                      {title}
                      <br />
                      {dashboard !== "manager" && "Progress Bar"}
                    </h5>
                  </Col>
                </Row>
              </Col>

              <Col style={{ height: '100%', overflowY: 'scroll' }} span={visible ? 17 : 0} >
                <Spin style={{ height: 'inherit' }} spinning={loading}>
                  {!loading && (
                    <Row style={{ height: 'inherit' }} justify={"space-between"}>

                      {dashboard !== "manager" && (
                        <Col style={{ height: '100%' }}>
                          <PersonalEditCommitmentView
                            setIsManageComms={setIsManageComms}
                            isManageComms={isManageComms}
                            defaultCustomUserCommitments={
                              defaultCustomUserCommitments
                            }
                            allDefaultCommitmentsProgress={
                              dashboard === "manager-personal"
                                ? {
                                  edges: allDefaultCommitmentsProgress?.edges
                                    ?.length
                                    ? [].concat.apply(
                                      ...(allDefaultCommitmentsProgress?.edges?.map(
                                        ({ node }) =>
                                          node?.commitment?.coachingcommitmentSet?.edges?.map(
                                            ({ node }) => ({
                                              node,
                                            })
                                          )
                                      ) ||
                                        [] ||
                                        [])
                                    )
                                    : [],
                                }
                                : null
                            }
                            customUserCommitments={customUserCommitments}
                            dashboard={dashboard}
                            commitmentKey={commitmentKey}
                            forUser={forUser}
                          />
                        </Col>
                      )}

                      <Col span={24} style={{ height: "fit-content", overflowY: 'scroll', padding: '1em 2em', marginLeft: '0.7em' }}>
                        {defaultCustomUserCommitments?.edges?.length > 0
                          ? defaultCustomUserCommitments?.edges?.map(
                            ({ node }, idx) => (
                              <>
                                <Row gutter={24} align="middle">
                                  <CommitmentComponent
                                    dashboard={dashboard}
                                    commitment={node[commitmentKey]}
                                    completed={node.completed}
                                    dateCreated={node?.dateCreated}
                                    dateCompleted={node?.dateCompleted}
                                    onDefaultCommitmentProgress={(values) =>
                                      onDefaultCommitmentProgress({
                                        id: getIntFromString(node?.id),
                                        ...values,
                                      })
                                    }
                                    setUpdated={() => setUpdated(updated + 1)}
                                    defaultCustomUserCommitment={true}
                                  />
                                </Row>
                                <br />
                              </>
                            )
                          )
                          : customUserCommitments?.edges?.length === 0 && (
                            <div align="center">
                              <Empty />
                            </div>
                          )}
                        {/* team member commitments */}
                        {customUserCommitments?.edges?.length > 0 &&
                          customUserCommitments?.edges?.map(({ node }, idx) => (
                            <>
                              <Row gutter={24} align="middle">
                                <CommitmentComponent
                                  customUserCommitments={customUserCommitments}
                                  index={idx}
                                  dashboard={dashboard}
                                  commitment={node}
                                  completed={node?.completed}
                                  dateCreated={node?.dateCreated}
                                  dateCompleted={node?.dateCompleted}
                                  onCustomUserCommitment={
                                    onCustomUserCommitment
                                  }
                                  setUpdated={() => setUpdated(updated + 1)}
                                  defaultCustomUserCommitment={false}
                                />
                              </Row>
                              <br />
                            </>
                          ))}

                        {getMyCustomizedCoachingCommitments?.edges?.length >
                          0 &&
                          getMyCustomizedCoachingCommitments?.edges?.map(
                            ({ node }, idx) => (
                              <>
                                <Row gutter={24} align="middle">
                                  <CommitmentComponent
                                    defaultCustomUserCommitments={defaultCustomUserCommitments}
                                    customUserCommitments={
                                      customUserCommitments
                                    }
                                    dashboard={dashboard}
                                    commitment={node}
                                    completed={node?.completed}
                                    dateCreated={node?.dateCreated}
                                    dateCompleted={node?.dateCompleted}
                                    onCustomUserCommitment={
                                      onCustomUserCommitment
                                    }
                                    setUpdated={() => setUpdated(updated + 1)}
                                    defaultCustomUserCommitment={false}
                                  />
                                </Row>
                                <br />
                              </>
                            )
                          )}
                      </Col>
                    </Row>
                  )}
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </>
    </Contextual.Provider>
  );
};

export default CommitmentView;
