import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { Col, Card, Row, Space, Spin, Progress, Form, Empty, Tooltip } from 'antd';
import {BulbTwoTone} from '@ant-design/icons'
import '../../dashboard/css/EmployeeResilienceAnchorsView.css';
import { getStrokeColor } from '../../look';
import { CloseOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const EmployeeResilienceAnchorsView = props => {
  const { loading, resilienceAnchors, title = 'My Resilience', memberName,  tipsData, setMemPaneClosed } = props;
  const [visible, setVisible] = useState(false);
  const [selectedAnch, setSelectedAnch] = useState({factor:'', anchor:'', percentage:0});
  const [tip, setTip] = useState([])
  const [anchorHovered, setAnchorHovered] = useState(false);
  const [selectedAnchor, setSelectedAnchor] = useState()
  const scrollRef = useRef(()=>{})


  let titleFirstLetterToUpper = title.charAt(0).toUpperCase()
  let titleFirstLetterRemoved = title.slice(1)
  function getAnchor(anchor) {
    // if (visible?.anchor === anchor) setVisible(null);
    setVisible({ anchor, anchorData: JSON.parse(resilienceAnchors)?.socres[anchor] });
    setMemPaneClosed(false)
    scrollToElement()
  }

  const scrollToElement = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth', // Optional: Adds smooth scrolling behavior
        block: 'start', // Optional: Scrolls to the top of the element
      });
    }
  };

  useEffect(()=>{
    if(visible){
      scrollToElement()
    }
  },[visible])

  // useEffect(()=>{
  //   if(!perCommOpen && perCommOpen !== undefined){
  //     scrollToElement()
  //     // triggerAnimation()
  //   }
  // },[perCommOpen])

  useEffect(()=>{
    if(tipsData && selectedAnch){
      let selectedTip = tipsData?.filter(item =>  item.node.anchor?.toLowerCase().replace(/[ \W_]+/g, '') === selectedAnch?.anchor?.toLowerCase().replace(/[ \W_]+/g, '') && item.node?.subFactor?.toLowerCase().replace(/[ \W_]+/g, '') === selectedAnch.factor?.toLowerCase().replace(/[ \W_]+/g, '') && item?.node?.tipFor === "MANAGERS")
      // console.log('selectedTip5u6ufr4',selectedTip,selectedAnch)
      console.log('selectedTipmem33r',selectedTip,tipsData,selectedAnch,visible)
      if(Number(selectedAnch?.percentage) > 65) {
        let requiredTips = selectedTip?.filter(tip => tip?.node?.category === 'DEVELOPING')
        setTip(requiredTips)
      } else {
        let requiredTips = selectedTip?.filter(tip => tip?.node?.category === 'MAINTENACE')
        setTip(requiredTips)
      } 
      
    }
  },[selectedAnch,tipsData])

  const parsedData = resilienceAnchors && JSON.parse(JSON.stringify(resilienceAnchors))

  const renamer = (anchor,factor,percentage) => {

    setSelectedAnch({anchor:factor.replace(/[ \W_]+/g, '').toLowerCase(), factor: anchor.replace(/[ \W_]+/g, '').toLowerCase(), percentage})

  }
  // resilienceAnchors && console.log('anch39tipsfe3fu::',resilienceAnchors, visible, parsedData["Error"] )
  return (
    <Col 
    ref={scrollRef}
    onClick={()=> resilienceAnchors  && !visible && getAnchor(`${anchorHovered ? selectedAnchor : "Identity" }`)}
    style={{cursor:'pointer', scrollMarginTop:'70px'}}
    className={`transition-col ${visible ? "visible" : "hidden"}`}
    span={visible ? 24 : 8}>
      {
        resilienceAnchors && Object.keys(JSON.parse(resilienceAnchors)?.socres)?.length ?
      <Card
        style={{ display: 'grid', height: '100%', marginBottom:'2em' }}
        bodyStyle={{
          boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
          borderRadius: '2%'
        }}
        bordered={false}
      >
        <Spin spinning={loading}>
          <Row style={{display:`${visible ? 'flex':'none'}`, justifyContent:'flex-end', justifyContent:'flex-end'}} span={24}>
                            <Row align='right' style={{ alignSelf: 'right' }}>
                  <Col span={4} align="right">
                    <CloseOutlined style={{fontSize: "20px"}} onClick={() => {setVisible(false);setMemPaneClosed(true)}} />
                  </Col>
                </Row>
          </Row>
          <Row gutter={24}>
            <Col span={visible ? 24 : 24}>
              <div align="center">
                <Row align="middle" style={{ marginTop: '22px' }}>
                  <Col span={24}>
                    <h2 style={{ fontWeight: 'normal', wordWrap: 'normal' }}>
                      {` ${memberName ? memberName?.map(a => a.charAt(0).toUpperCase() + a.substr(1)) + "'s" : 'My'} Resilience `}
                      Anchors
                    </h2>
                  </Col>
                </Row>

                <Col span={24}>
                  <Row style={{margin:'20px 0px 24px 0px', display:'flex', justifyContent:'center',alignItems:'center'}} justify="center">
                    {resilienceAnchors && Object.keys(JSON.parse(resilienceAnchors)?.socres)?.length ? (
                      Object.keys(JSON.parse(resilienceAnchors)?.socres)?.map((soc, index) => (
                        <Col style={{width:'fit-content'}} span={visible ? 4 : 15} align="left">
                          <HoverSpace
                            size="middle"
                            align="center"
                            
                            onClick={() => {
                             resilienceAnchors && getAnchor(soc);
                             resilienceAnchors && selectedAnch?.anchor != soc && setSelectedAnch({factor:'', anchor:'', percentage:0})
                            }}
                            className='res_anchor_hover'
                          >
                            <SequenceNo active={visible && visible.anchor} soc={soc}>
                              {index + 1}
                            </SequenceNo>
                            <Colordiv  onMouseEnter={()=>{setAnchorHovered(true);setSelectedAnchor(soc)}} onMouseLeave={()=>{setAnchorHovered(false);setSelectedAnchor(null)}} active={visible && visible.anchor} soc={soc}>
                              {soc}
                            </Colordiv>
                          </HoverSpace>
                        </Col>
                      ))
                    ) : (
                      <>
                        <br />
                        <br />
                        <Empty />
                      </>
                    )}
                  </Row>
                </Col>
              </div>
            </Col>

            <Col span={visible ? 24 : 0}>
              <Row style={{ display: 'flex', flexDirection:'column' }}>
                {/* <Row style={{ alignSelf: 'flex-end' }}>
                  <Col span={4} align="right">
                    <CloseOutlined onClick={() => setVisible(null)} />
                  </Col>
                </Row> */}

                <Row style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <Col span={22} style={{ minHeight: '25vh',  }}>
                    <Row align={'middle'} style={{  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width:'100%' }}>
                      <Col style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}} span={6}>
                        <h2 style={{ fontWeight: 'normal' }}>{visible?.anchor}</h2>
                        <Progress
                        className='antD_circle'
                          type="circle"
                          percent={(visible?.anchorData?.overall * 10).toFixed(0)}
                          strokeColor="#B0CB1F"
                          strokeWidth="10"
                          size='default'
                        />
                      </Col>

                      <Col style={{display:'flex',flexDirection:'column', justifyContent:'center', width:'max-content'}} span={14}>

                        {resilienceAnchors && visible &&
                          Object.keys(visible?.anchorData?.factors)?.map(factor => (
                            <>

                        <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 18, offset: 1 }} label={ <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                          <p>{factor}</p>
                          <Tooltip placement='top' overlayInnerStyle={{width:'560px', borderRadius:'10px'}} title={
                            <div style={{display:'flex',flexDirection:'column',justifyContent:'space-evenly',alignItems:'center'}}>
                              <p>{tip?.length > 0 ? <h2 className='res_anchor' style={{ fontWeight: 'normal' }}>Tip for the {factor} Parameter</h2> : ''}</p>
                            {tip?.length > 0 ? tip?.map(message => <p>{message?.node?.content}</p>) : <p>Exclusive insights await!</p>}
                            </div>
                            }>
                          <HoverSpace 
                          onMouseOver={() => {renamer(factor,visible?.anchor,(visible?.anchorData?.factors[factor] * 10).toFixed(0))}}
                          >
                            <BulbTwoTone style={{marginLeft:'3px'}}/>
                          </HoverSpace>
                        </Tooltip>
                        </div> }>
                              <Progress
                                percent={(visible?.anchorData?.factors[factor] * 10).toFixed(0)}
                                strokeColor={getStrokeColor((visible?.anchorData?.factors[factor]).toFixed(2) || 0)}
                              />
                            </FormItem>
                            </>

                          ))}
                      </Col>
                    </Row>

                  </Col>
                  {/* {selectedAnch.factor && <Col span={selectedAnch.factor ? 14 : 0} style={{ width: 'max-content' }}>
                    <Row>
                      <h2 className='res_anchor' style={{ fontWeight: 'normal' }}>Tip for the {selectedAnch.factor} Parameter</h2>
                    </Row>
                    {tip?.length > 0 ? tip?.map(message => <p>{message?.node?.content}</p>) : <p>Sorry There are no tipsData available in this Case</p> }

                  </Col>} */}
                </Row>

              </Row>
            </Col>
          </Row>
        </Spin>
      </Card>
      :
      <Card
      style={{ display: 'grid', height: '100%', marginBottom:'2em' }}
      bodyStyle={{
        boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
        borderRadius: '2%',
        display:`${!resilienceAnchors ? 'flex' : 'block'}`,
        justifyContent:'center',
        alignItems:'center'
      }}
      bordered={false}
    >
        <Spin spinning={loading}>

      {!loading && 
      <Col span={24}>
                  <Row style={{display:`${visible ? 'flex':'none'}`, justifyContent:'flex-end', justifyContent:'flex-end'}} span={24}>
                            <Row align='right' style={{ alignSelf: 'right' }}>
                  <Col span={4} align="right">
                    <CloseOutlined style={{fontSize: "20px"}} onClick={() => {setVisible(false);setMemPaneClosed(true)}} />
                  </Col>
                </Row>
          </Row>
      <h2 style={{ fontWeight: 'normal', wordWrap: 'normal' }}>{`Sorry, the Resilience anchors for ${memberName && memberName?.map(a => a.charAt(0).toUpperCase() + a.substr(1)) + " are not available yet"}`}</h2>
      
      </Col>
      }
      </Spin>
    </Card>
      }
    </Col>
  );
};

export  {EmployeeResilienceAnchorsView};

const SequenceNo = styled.div`
  border-radius: 50%;
  width: 28px;
  color: ${props => (props.active === props.soc ? '#B0CB1F' : '#222')};
  height: 28px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  border: 1px solid #cacaca;
`;

const HoverSpace = styled(Space)`
  margin-bottom: 10px;
  &:hover {
    color: PRIMARY_CLR;
    cursor: pointer;
  }
`;
const Colordiv = styled.div`
  color: ${props => (props.active === props.soc ? '#B0CB1F' : '#222')};
`;